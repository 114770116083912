.join-box2 {
  background: url('../../img/forwarder/huozhu.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 45vh;

  .job-top-content {
    text-align: center;
    top: 50%;
  }
  .title-top {
    color: #fff;
    font-size: 42px;
  }
  .title-english {
    color: #bbb;
    font-size: 32px;
  }
}
.job-title {
  img {
    margin: 15px auto;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
.show {
  display: block;
}
.hiden {
  display: none;
}
.job-describe {
  padding: 10px 24px 10px 34px;
  background-color: #f8f8f8;

  .job-describe-title {
    font-size: 14px;
    color: #555;
    position: relative;
    padding-left: 12px;
    line-height: 22px;
    height: 22px;
    margin: 10px 0;

    &:before {
      content: '';
      width: 4px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: 0;
      background-color: #ff9d29;
    }
  }
}
.lineList {
  border-bottom: dashed 1px #ddd;
  font-size: 16px;
}
.companyName {
  font-size: 18px;
  color: #ff6700;
  padding: 0 0 0 26px;
  background: url('../../img/forwarder/icon.png') no-repeat -3px -459px;
  margin-left: 5px;
  a {
    color: #3d85ff;
    // font-weight: bold;
    &:hover {
      text-decoration: underline !important;
      color: #ff6700;
    }
  }
  // a:hover {
  //   text-decoration: underline !important;
  //   color: #ff6700;
  // }
}
.xianlu {
  color: #333;
  text-decoration: none;
  font-size: 12px;
  padding: 0 0 0 26px;
  background: url('../../img/forwarder/icon.png') no-repeat -4px -434px;
  margin-left: 5px;
}
.introTip {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  padding: 0 0 0 26px;
  margin-left: 5px;
}

.leftAddress {
  font-size: 12px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 0 26px;
  background: url('../../img/forwarder/icon.png') no-repeat -3px -406px;
  margin-left: 5px;
}
.shixiao {
  color: #333;
  text-decoration: none;
  font-size: 12px;
  padding: 0 0 0 26px;
}
.dengji {
  background: url('../../img/forwarder/2.gif');
}
.rzlogo {
  display: inline-block;
  height: 30px;
  padding: 0 5px 0 30px;
  color: #feff04;
  line-height: 31px;
  font-size: 12px;
  cursor: pointer;
}
.newFaHuoBtn {
  display: block;
  width: 86px;
  height: 24px;
  margin: 5px auto 0;
  line-height: 24px;
  text-align: center;
  color: #ff7300;
  font-size: 14px;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #ff7300;
}
.newFaHuoBtn:hover {
  background: #ff7300;
  color: #fff;
}
.container_new {
  width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 20px;
}
.container_new2 {
  width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 20px;
}
.topNam {
  width: 100%;
  background: #fff;
  height: auto;
  margin-top: 20px;
}
.top_com {
  width: 1200px;
  margin: 0 auto;
  height: 60px;
}
.liuLan {
  color: #666;
  font-size: 12px;
}
.liuLan p {
  height: 30px;
  line-height: 30px;
}
.newXianLu a {
  display: inline-block;
  color: #333;
  margin-right: 5px;
}
.container_new .xjj_head {
  height: 65px;
  line-height: 65px;
  border-bottom: 2px solid #faab35;
}
.container_new .xjj_head .xjj_topic {
  position: relative;
  bottom: 8px;
}
.fl {
  float: left;
}
.xjj-title {
  width: 70%;
  text-align: center;
  margin: 0 auto;
}
.xjj-title .xl_name {
  font-size: 22px;
  color: #fb773e;
}
.branch {
  padding: 15px 80px 0;
  font-size: 14px;
  position: relative;
}
.branch-t {
  height: 30px;
}
.phcommonTit-box h1,
.PhMainBuinessLine h1 {
  width: 110px;
  text-align: center;
  height: 60px;
  line-height: 80px;
  font-size: 28px;
  // font-weight: bold;
}
.tabn {
  height: 60px;
  width: 110px;
  border-bottom: 2px #2a8ad4 solid;
}
.SupplyInfor-cur {
  font-size: 22px;
  height: 60px;
  line-height: 80px;
  text-align: center;
}
.comTuPian {
  font-size: 14px;
  color: #666;
  line-height: 28px;
  text-align: justify;
}
.paddingBottom30 {
  padding-bottom: 30px;
}

ul {
  list-style: outside none none;
}
.subWapper .list1 {
  margin-bottom: 20px;
}

.subWapper .list1 {
  float: left;
  overflow: hidden;
  width: 220px;
  padding: 10px 20px 20px;
  background: #f6f6f6;
  line-height: 45px;
  cursor: pointer;
  margin-right: 20px;
}
.wlSubList li p,
.phSubList li p {
  height: 45px;
  line-height: 45px;
}
.subList li p {
  color: #8f8f8f;
  font-size: 14px;
}
.zhongHuo {
  color: #888888;
  padding: 3px 8px;
  background: #fff7dd;
  border: 1px solid #f3d67a;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 10px;
}
.jiaGe {
  color: #ff7300;
}
.qingHuo {
  color: #888888;
  padding: 3px 8px;
  background: #fff7dd;
  border: 1px solid #f3d67a;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 10px;
}
.ve_main_list_tit {
  width: 1150px;
  height: 59px;
  border: 1px solid #ddd;
  border-top: none;
}
.ve_main_list li.list_tit_i {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ve_main_list_tit li {
  float: left;
  line-height: 59px;
  border-right: 1px solid #dedede;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.ve_main_list li.list_tit_j {
  width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ve_main_list_tit li {
  float: left;
  line-height: 59px;
  border-right: 1px solid #dedede;
  border-top: 1px solid #dedede;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.ve_main_list li.list_tit_c {
  width: 100px;
}
.ve_main_list_bo li {
  float: left;
  height: 59px;
  line-height: 59px;
  color: #333;
  text-align: center;
  margin-right: 1px;
}
.ve_main_list li.list_tit_j {
  width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ve_main_list_bo li {
  float: left;
  height: 59px;
  line-height: 59px;
  color: #333;
  text-align: center;
  margin-right: 1px;
}
.ve_main_list_bo ul.bg_grey {
  background: #efefef;
}
.ve_main_list_bo ul {
  height: 59px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-top: none;
}
.ve_main_list_bo {
  width: 1150px;
  height: auto;
  background: #fff;
  cursor: pointer;
}
ul,
ol {
  list-style: outside none none;
}
.ve_main_list li.list_tit_c a {
  display: inline-block;
  width: 70px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #e60012;
  line-height: 30px;
  color: #e60012;
  margin: 12px 0 0 0;
  background: #fff;
}
.ve_main_list_bo li.list_tit_c {
  margin-right: 0;
}
.g-title {
  padding: 5px;
  font-size: 14px !important;
  height: 45px;
  line-height: 30px;
}
.t-c {
  padding: 5px;
  font-size: 14px !important;
  height: 45px;
  line-height: 30px;
}
.intro_xq_addr_box_c {
  width: 1170px;
  border: 1px solid #eaeaea;
  height: 125px;
  background: #f8f8f8;
  text-align: center;
}
.intro_xq_addr_box_x {
  width: 870px;
  margin: 0 auto;
  height: 124px;
}
.intro_xq_addr_box_x > span,
.intro_xq_addr_box_x > div {
  float: left;
  line-height: 124px;
}
.intro_xq_addr_box_x > span {
  width: 310px;
}
.intro_xq_addr_box_x > span i {
  display: inline-block;
  width: 25px;
  height: 34px;
  vertical-align: middle;
  margin-right: 10px;
}
.intro_addr_s i {
  background: url(../../img/forwarder/ma_q.png) no-repeat;
}
.intro_xq_addr_box_x > span em {
  font-size: 20px;
  color: #333;
}
.intro_addr_s em,
.intro_addr_z em {
  display: inline-block;
  max-width: 270px;
  vertical-align: middle;
  line-height: 28px;
  text-align: left;
}
address,
cite,
dfn,
em,
var {
  font-style: normal;
}
.intro_xq_addr_box_x > span,
.intro_xq_addr_box_x > div {
  float: left;
  line-height: 124px;
}
.intro_addr_m {
  position: relative;
  margin: 0 10px;
}
.intro_addr_m span.ma_blue {
  color: #008aff;
  background: #dcedfb;
  border: 1px solid #5e9aff;
  left: 96px;
}
.intro_addr_m span {
  font-size: 12px;
  color: #e7000e;
  background: #ffeaea;
  border: 1px solid #e7000e;
  border-radius: 4px;
  position: absolute;
  top: 35px;
  left: 0;
  display: inline-block;
  width: 36px;
  height: 18px;
  line-height: 18px;
}
.intro_addr_m > i {
  display: inline-block;
  width: 229px;
  height: 6px;
  background: url(../../img/forwarder/ma_icon.png) no-repeat;
}
.intro_addr_m > em {
  font-size: 14px;
  color: #333;
  position: absolute;
  top: 30px;
  width: 185px;
  text-align: center;
  left: 45px;
  line-height: 16px;
}
.intro_addr_m > em.intro_addr_m_s {
  font-size: 16px;
  color: #333;
  position: absolute;
  top: 75px;
  width: 229px;
  text-align: center;
  left: 0;
  line-height: 16px;
}
.intro_xq_addr_box_x > span,
.intro_xq_addr_box_x > div {
  float: left;
  line-height: 124px;
}
.intro_xq_addr_box_x > span {
  width: 310px;
}

.com_intro_addr_box {
  width: 1200px;
  margin: 20px auto;
}
.com_intro_addr_box_l {
  float: left;
}
.com_intro_addr_box .business_detail_cont {
  width: 590px;
}
.com_intro_addr_title {
  font-size: 16px;
  font-weight: bold;
  width: 570px;
  color: #333;
  background: #e5e5e5;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
}
.com_intro_addr_box .vehicle_select {
  // width: 580px;
  border-bottom: none;
}
.vehicle_select {
  // width: 1198px;
  height: auto;
  // border: 1px solid #ddd;
  margin: 0 auto;
}
.com_intro_addr_box .vehicle_select_list dl {
  width: 570px;
}
.vehicle_select_list dl {
  width: 1198px;
  border-bottom: 1px solid #ddd;
  background: #efefef;
  overflow: hidden;
}
.com_intro_addr .vehicle_select_list dl dt {
  font-weight: normal;
}
.vehicle_select_list dl dt {
  width: 100px;
  float: left;
  padding-left: 16px;
  padding-right: 4px;
  font-weight: bold;
  color: #333;
  text-align: right;
  padding-top: 10px;
  line-height: 35px;
}
.vehicle_select_list dd {
  float: left;
  padding-right: 4px;
  color: #333;
  text-align: right;
  padding-top: 10px;
  line-height: 35px;
}
.com_intro_addr_box_r {
  float: right;
}
.com_zx_pri {
  border: 1px solid #e5e5e5;
  position: relative;
  height: 100%;
}
.com_zd_pri {
  height: 51px;
  line-height: 51px;
}
.com_zx_pri_l {
  width: 125px;
  background: #e5e5e5;
  text-align: center;
  float: left;
}
.com_zx_pri_l p {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.com_zx_pri .com_zd_pri_r {
  padding-left: 155px;
  padding-bottom: 0;
}
.com_zd_pri_d {
  font-size: 14px;
  color: #e60012;
  font-weight: bold;
  line-height: 51px;
}
.clear {
  clear: both;
}
.com_addr_f {
  border: 1px solid #e5e5e5;
  margin-top: 20px;
}
.com_addr_f_l {
  width: 125px;
  height: 100px;
  background: #e5e5e5;
  line-height: 100px;
  text-align: center;
  float: left;
}
.com_addr_f_l p {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  width: 85px;
  line-height: 24px;
  margin: 26px auto 0 auto;
}
.com_addr_f_r {
  float: left;
}
.com_addr_f_r p {
  text-align: center;
  width: 873px;
  height: 100px;
}
.com_addr_f_r em {
  display: inline-block;
  width: 135px;
  height: 82px;
  background: url(../../img/forwarder/ma_intr_no.png) no-repeat;
  margin-top: 9px;
}

.intro_addr_z i {
  background: url(../../img/forwarder/ma_z.png) no-repeat;
}

.host_city_pri2 {
  margin-top: 5px;
  display: flex;
  margin-top: 15px;
}
.host_city_pri2 > span {
  float: left;
}
.host_city_pri2 span {
  color: #333;
}
.host_city_pri2 span i.host_city_pri_z2 {
  background: url(../../img/forwarder/ma_i_z_r.png) no-repeat;
}
.host_city_pri2 > span > i {
  display: inline-block;
  width: 17px;
  height: 18px;
  vertical-align: middle;
  margin: 0 5px;
}
.host_city_pri_l2 {
  // margin-left: 25px;
}
.host_city_pri2 span i.host_city_pri_p2 {
  background: url(../../img/forwarder/ma_i_p_r.png) no-repeat;
}
.host_city_pri2 span span i {
  color: #e60012;
  font-style: normal;
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  margin: -2px 3px 0 5px;
}

.btn-a {
  display: inline-block;
  width: 200px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #e60012;
  line-height: 30px;
  color: #e60012;
  margin: 12px 0 0 0;
  background: #e60012;
  text-align: center;
  color: #ffffff;
  padding-top: 4px;
  font-weight: bold;
}
a.btn-a:hover {
  color: #ffffff;
}
