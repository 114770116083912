@active-color: #3d85ff;
@header: header0;
@font14: 14px;
.@{header} {
  height: 80px;
  background: @template-nav-bg-color;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px fade(#000, 15);
  position: relative;
  top: 0;
  .home-page {
    padding: 0 10px;
    max-width: 1300px;
  }
  &-logo {
    display: inline-block;
    position: relative;
    width: 100px;
    line-height: 80px;
    & img {
      vertical-align: middle;
      display: inline-block;
    }
  }
  &-menu {
    float: right;
    > .ant-menu {
      line-height: 80px;
      background: transparent;
      color: @template-text-color-black;
      height: 80px;
      position: relative;
      li {
        height: 100%;
        line-height: 80px;
        border-bottom-color: transparent !important;
      }
      a {
        font-family: PingFangSC-Regular, PingFang SC;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        font-size: 22px;
        color: #0c0c0c;
        &:hover {
          color: @active-color;
        }
      }
      .header-login-text {
        width: 90px;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1) !important;
        line-height: 28px;
      }
    }
  }
  .ant-menu-submenu-title {
    &:hover {
      span {
        box-sizing: border-box;
        border-bottom: 2px solid @active-color;
        &::after {
          height: 5px;
          position: absolute;
          bottom: -3px;
          right: 0;
          content: '';
          z-index: 2;
          animation: clipMe 1s linear 0s 1 forwards;
          background-color: #fff;
        }
      }
    }
    .submenu-title-wrapper {
      display: inline-block;
      font-size: 22px;
      height: 40px;
      line-height: 40px;
    }
  }
  .ant-menu-item-selected a {
    color: @primary-color;
  }
}
.ant-menu-submenu-popup {
  // top: 78px !important;
  .ant-menu-sub {
    border-radius: 0% !important;
    box-shadow: none !important;
    margin-top: -11px !important;
  }
}
.ant-menu-submenu {
  .ant-menu-vertical .ant-menu-item {
    margin: 0 !important;
    padding: 0 20px !important;
    a {
      color: #0e0e0e !important;
      font-size: 18px !important;
    }
  }
  .ant-menu-sub {
    .ant-menu-item-selected {
      background-color: @active-color !important;
      a {
        color: #fff !important;
      }
    }
  }
}
.ant-menu-horizontal > .ant-menu-item > a {
  display: inline-block !important;
  color: #0c0c0c !important;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: @active-color !important;
  padding-bottom: 6px;
  box-sizing: border-box;
  border-bottom: 2px solid @active-color;
  position: relative;
  &::after {
    height: 5px;
    position: absolute;
    bottom: -3px;
    right: 0;
    content: '';
    z-index: 2;
    animation: clipMe 500ms linear 0s 1 forwards;
    background-color: #fff;
  }
}

@keyframes clipMe {
  0% {
    width: 100%;
  }
  25% {
    width: 75%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 25%;
  }
  100% {
    width: 0;
  }
}

.ant-menu-item,
.ant-menu-submenu-title {
  // padding: 0 50px !important;
  padding: 0 40px !important;
}
.mynavClass {
  background-color: rgba(255, 255, 255, 1) !important;
  position: fixed !important;
  top: 0;
  height: 100px;
}
.header0-logo {
  margin-top: 30px;
}
.title-line {
  width: 50px;
  height: 3px;
  background-color: rgb(251, 251, 251);
  margin: 0px auto;
}
.title-black-line {
  width: 50px;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.65);
  margin: 0px auto;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: @active-color !important;
}
.isphoneshow {
  display: none;
}
.tip-modal {
  position: fixed;
  top: 155px;
  right: 20px;
  padding: 15px 21px 7px;
  background-color: #6a6a77;
  transform: translate(0px, 0px);
  z-index: 9999999;
  width: 90px;
  height: 90px;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  img {
    display: block;
    margin: 0px auto 2px;
    width: 46px;
  }
  &:hover {
    background-color: @active-color;
  }
}
.tip1 {
  .isTryNow {
    display: none;
  }
  .notTryNow {
    display: block;
  }
  &:hover {
    .isTryNow {
      display: block;
    }
    .notTryNow {
      display: none;
    }
  }
}
.tip2 {
  top: 295px;
  .tip2-modal-hover {
    width: 100%;
    height: 100%;
  }
  .tellShow {
    display: none;
  }
  .isServiceCall {
    display: none;
  }
  .notServiceCall {
    display: block;
  }
  &:hover {
    background-color: #fff;
    width: 250px;
    padding: 0;
    .tip2-modal-hover {
      width: 90px;
      background: #3d85ff;
      height: 90px;
      border-radius: 10px;
      padding: 15px 21px;
      float: left;
    }
    .tellShow {
      display: inline-block;
      width: 144px;
      img {
        width: 100%;
        margin-top: 32px;
      }
    }
    .isServiceCall {
      display: block;
    }
    .notServiceCall {
      display: none;
    }
  }
}
.tip3 {
  top: 435px;
  .isWechart {
    display: none;
  }
  .notWechart {
    display: block;
  }
  .wechart-hover-describe {
    display: none;
  }
  &:hover {
    .isWechart {
      display: block;
    }
    .notWechart {
      display: none;
    }
    .wechart-hover-describe {
      display: block;
      height: 140px;
      width: 155px;
      position: absolute;
      top: -25px;
      left: -160px;
    }
  }
}
@media screen and (max-width: 767px) {
  .tel {
    display: none;
  }
  .isphoneshow {
    display: block;
  }
  .home-page-wrapper {
    overflow: visible;
  }
  .@{header} {
    &-logo {
      z-index: 101;
    }
    &.home-page-wrapper .home-page {
      padding: 0 24px;
    }
    & &-menu {
      height: 80px;
      float: inherit;
      position: relative;
      left: -24px;
      width: ~'calc(100% + 48px)';
      opacity: 0;
      transition: opacity 0.3s @ease-in-out, height 0.3s @ease-in-out;
      & li {
        padding: 0 24px;
        &.ant-menu-submenu {
          padding: 0;
        }
      }
      & .ant-menu-submenu .ant-menu-sub {
        padding: 0 24px;
      }
    }
    &-mobile-menu {
      width: 16px;
      height: @font14;
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 24px;
      z-index: 100;
      em {
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        margin-top: 4px;
        transition: transform 0.3s @ease-in-out, opacity 0.3s @ease-in-out;
      }
      :first-child {
        margin-top: 0;
      }
    }
    .ant-menu {
      height: 80px;
      overflow: hidden;
      border-bottom: none;
      box-sizing: border-box;
      background: transparent;
      .ant-menu-item-selected {
        border: none;
      }
      a {
        font-size: @font14;
        color: @template-text-color-black;
        &:hover {
          color: @template-text-color-black;
        }
      }
      .ant-menu-item-selected a {
        color: @template-text-color-black;
      }
    }
    & .open {
      height: auto;
      .@{header}-mobile-menu {
        em {
          &:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }
      > .@{header}-menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .tip-modal {
    position: fixed;
    top: 100px;
    right: 5px;
    padding: 4px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    text-align: center;
    img {
      margin: 0 auto;
      width: 30px;
    }
    p {
      font-size: 10px;
    }
  }
  .tip2 {
    top: 160px;
    &:hover {
      width: 200px;
      .tip2-modal-hover {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        padding: 5px;
      }
      .tellShow {
        display: inline-block;
        width: 144px;
        img {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }
  .tip3 {
    top: 220px;
    &:hover {
      .wechart-hover-describe {
        height: 110px;
        width: 120px;
        top: -30px;
        left: -125px;
      }
    }
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    padding: 10px !important;
  }
  .ant-menu-submenu .ant-menu-vertical .ant-menu-item a {
    font-size: @font14 !important;
  }
  .header0 .ant-menu-submenu-title .submenu-title-wrapper {
    font-size: @font14;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    padding: 0 10px !important;
  }
}
