.join-box {
  background: url('../../img/DespatchingCenter/fahuozhongxin.jpg') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 45vh;

  .job-top-content {
    text-align: center;
    top: 50%;
  }
  .title-top {
    color: #fff;
    font-size: 42px;
  }
  .title-english {
    color: #bbb;
    font-size: 32px;
  }
}
.job-title {
  img {
    margin: 15px auto;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
.show {
  display: block;
}
.hiden {
  display: none;
}
.job-describe {
  padding: 10px 24px 10px 34px;
  background-color: #f8f8f8;

  .job-describe-title {
    font-size: 14px;
    color: #555;
    position: relative;
    padding-left: 12px;
    line-height: 22px;
    height: 22px;
    margin: 10px 0;

    &:before {
      content: '';
      width: 4px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: 0;
      background-color: #ff9d29;
    }
  }
}
.goods-container {
  width: 1000px;
  margin: 0 auto;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 20px;
  margin-top: 20px;
}
.g-title {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  padding-top: 6px;
  border: 1px solid #e4e4e4;
  border-collapse: collapse;
}
.t-c {
  font-size: 16px;
  padding-top: 6px;
  padding-left: 6px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-collapse: collapse;
}

.c6-warper {
  width: 1320px;
  margin: 40px auto;
  overflow: hidden;
}
.c6-header {
  height: 65px;
  display: flex;
  align-items: center;
  background-color: #3d85ff;
  color: #ffffff;
  font-size: 24px;
  justify-content: space-between;
  div:first-child {
    margin-left: 25px;
  }
  div:last-child {
    margin-right: 25px;
    cursor: pointer;
    font-size: 18px;
  }
}
.b-warper {
  display: flex;
  flex-wrap: wrap;
}
.block-h {
  width: 305px;
  height: 198px;
  border: 1px solid #bfbfbf;
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.ma_host_city_l_c_t {
  height: 46px;
  line-height: 46px;
  background: #eeeded;
  text-align: center;
  position: relative;
}
.ma_host_city_l_c_t span {
  font-size: 18px;
  color: #333;
}
.ma_host_city_l_c_t em {
  display: inline-block;
  width: 67px;
  height: 18px;
  background: url(../../img/forwarder/ma_line.png) no-repeat;
  margin: 0 15px;
}
.ma_host_city_l_c_m {
  border-bottom: 1px dashed #ddd;
  padding: 9px 0 0 0;
  background: #fff;
  padding-bottom: 5px;
  text-align: center;
}
.ma_host_city_l_c_m > span {
  position: relative;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
}
.ma_host_city_l_c_m > span {
  width: 125px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  float: left;
  color: #666;
  line-height: 18px;
}
.ma_host_city_l_c_m span.host_city_last {
  margin-left: 30px;
}
.ma_host_city_l_c_m span::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 60px;
  background: -webkit-linear-gradient(left, transparent, #fff 55%);
  background: -o-linear-gradient(right, transparent, #fff 55%);
  background: -moz-linear-gradient(right, transparent, #fff 55%);
  background: linear-gradient(to right, transparent, #fff 55%);
}
.cleardiv {
  height: 0;
  clear: both;
  line-height: 0;
  font-size: 0;
  width: 100%;
}
.host_city_pri {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.host_city_pri > span {
  float: left;
}
.host_city_pri span {
  color: #333;
}
.host_city_pri span i.host_city_pri_z {
  background: url(../../img/forwarder/ma_i_z_r.png) no-repeat;
}
.host_city_pri > span > i {
  display: inline-block;
  width: 17px;
  height: 18px;
  vertical-align: middle;
  margin: 0 5px;
}
.host_city_pri_l {
  // margin-left: 25px;
}
.host_city_pri span i.host_city_pri_p {
  background: url(../../img/forwarder/ma_i_p_r.png) no-repeat;
}
.clear {
  clear: both;
}
.clear {
  clear: both;
  content: ' ';
  display: block;
  height: 0;
}
.host_city_pri span span i {
  color: #e60012;
  font-style: normal;
  // display: inline-block;
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  margin: -2px 3px 0 5px;
}
.host_city_pri span span em {
  color: #666;
}
address,
cite,
dfn,
em,
var {
  font-style: normal;
}
.ma_host_city_l_c_b {
  background: #fff;
}
.ma_host_city_l_c_b p {
  font-size: 14px;
  color: #333;
  line-height: 35px;
  width: 300px;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ma_host_city_l_c_b p em {
  display: inline-block;
  width: 16px;
  height: 18px;
  background: url(../../img/forwarder/ma_com.png) no-repeat;
  vertical-align: text-bottom;
  margin: 0 10px 0 5px;
}
.ma_host_city_l_c_b p {
  font-size: 14px;
  color: #333;
  line-height: 35px;
  width: 300px;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ma_host_be span {
  color: #333;
  margin-right: 20px;
}
.ma_host_be i {
  font-size: 18px;
  color: #e60012;
  font-weight: bold;
  font-style: normal;
}
.ma_host_be {
  text-align: right;
}
.ma_host_city_l_c_t i {
  font-size: 12px;
  display: inline-block;
  width: 36px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-style: normal;
  border-radius: 4px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.host_city_blue {
  color: #008aff;
  background: #dcedfb;
  border: 1px solid #5e9aff;
}
.shuaxin {
  width: 16px;
  height: 18px;
  background: url(../../img/forwarder/shuaxin.png) no-repeat;
  display: inline-block;
  vertical-align: text-bottom;
  margin: 0 10px 0 5px;
  background-size: 100%;
}
