@content5: content5;
.@{content5}-wrapper {
  background-color: #fafafa;
  min-height: 720px;
  .@{content5} {
    > p {
      text-align: center;
    }
    &-img-wrapper {
      margin: 0 auto;
      left: 0;
      right: 0;
      .block {
        margin-bottom: 24px;
        .content5-block-content {
          display: block;
          background: #fff;
          border-radius: 4px;
          padding: 10px;
          text-align: center;
          position: relative;
          overflow: hidden;
          .page-pro();
          border: none;
          transition: box-shadow 0.3s @ease-out, transform 0.3s @ease-out;
          & > span {
            width: 100%;
            height: 160px;
            display: block;
            background: @line-color;
            padding: 5%;
          }
          & p {
            width: 100%;
            line-height: 30px;
          }
          &:hover {
            & p {
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

.product-container {
  background-color: #f5f5f5;
  width: 100%;
  height: 30vh;
  .product-content {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    padding: 64px 24px;
    p {
      text-align: center;
      color: #0c0c0c;
      font-size: 40px;
    }
    .product-item-box {
      height: 170px;
      width: 230px;
      margin: 10px 0;
      padding-left: 16px;
      padding-right: 16px;
      .product-item {
        height: 100%;
        background-color: #ebf2ff;
        &:hover {
          background-color: #3d85ff;
          .product-content-box {
            p {
              color: #e7951e;
            }
            .block {
              display: block;
              position: absolute;
              top: 0;
              left: 50%;
              margin-left: -85px;
            }
          }
        }
        .product-bg {
          width: 100%;
          height: 100%;
        }
        .product-content-box {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 35px;
          p {
            color: #616366;
            font-size: 18px;
            margin-top: 10px;
          }
          span {
            width: 100%;
            text-align: center;
            padding: 2px 10px;
            color: #333;
            font-size: 12px;
            word-break: break-all;
          }
          .block {
            display: none;
          }
        }
      }
    }
  }
}
.prod-container {
  // p{text-align: center;color:#0C0C0C;font-size: 40px;}
  // height: 100%;
  // max-width: 1200px;
  position: relative;
  margin: 8px auto 40px;
  // padding: 10px 0px;

  .prod-item-box {
    height: 242px;
    padding: 0 7px;
    .prod-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 23px 15px;
      background-color: #f5f5f5;
      &:hover {
        background-color: #3d85ff;
        span {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
      .prod-item-hover {
        height: 136px;
        width: 200px;
        background: url('../../../img/homeYMYT/product/TMS2.png') no-repeat
          center;
        background-size: 100% 100%;
      }
      .prod-item-hover2 {
        background-image: url('../../../img/homeYMYT/product/OMS.png');
      }
      .prod-item-hover3 {
        background-image: url('../../../img/homeYMYT/product/WMS.png');
      }
      .prod-item-hover4 {
        background-image: url('../../../img/homeYMYT/product/wuliuzhutui.png');
      }
      span {
        width: 100%;
        display: inline-block;
        text-align: center;
        color: #5d5d5d;
        font-size: 16px;
        word-break: break-all;
        margin-top: 5px;
      }
      p {
        text-align: center;
        width: 143px;
        font-size: 24px;
        font-family: ToppanBunkyuMidashiGothicStdN-ExtraBold,
          ToppanBunkyuMidashiGothicStdN;
        font-weight: 800;
        color: rgba(85, 85, 85, 1);
        line-height: 22px;
        margin-top: 15px;
      }
    }
  }
}

.addv-container {
  p {
    text-align: center;
    color: #0c0c0c;
    font-size: 40px;
    margin-bottom: 15px;
  }
  max-width: 1200px;
  position: relative;

  .addv-item-box {
    height: 140px;
    margin: 10px 0;

    .addv-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #3d85ff;
        .addv-item-hover {
          background-image: url('../../../img/homeYMYT/addv/service01-h.png');
        }
        .addv-item-hover2 {
          background-image: url('../../../img/homeYMYT/addv/service02-h.png');
        }
        .addv-item-hover3 {
          background-image: url('../../../img/homeYMYT/addv/service03-h.png');
        }
        .addv-item-hover4 {
          background-image: url('../../../img/homeYMYT/addv/service04-h.png');
        }
        .addv-item-hover5 {
          background-image: url('../../../img/homeYMYT/addv/service05-h.png');
        }
        .addv-item-hover6 {
          background-image: url('../../../img/homeYMYT/addv/service06-h.png');
        }
        .addv-item-hover7 {
          background-image: url('../../../img/homeYMYT/addv/service07-h.png');
        }
        .addv-item-hover8 {
          background-image: url('../../../img/homeYMYT/addv/service08-h.png');
        }
        span {
          color: #fff;
        }
      }
      .addv-item-hover {
        height: 60%;
        width: 100%;
        margin: 5px auto;
        background: url('../../../img/homeYMYT/addv/service01.png') no-repeat
          center;
        background-size: 35%;
      }
      .addv-item-hover2 {
        background-image: url('../../../img/homeYMYT/addv/service02.png');
      }
      .addv-item-hover3 {
        background-image: url('../../../img/homeYMYT/addv/service03.png');
      }
      .addv-item-hover4 {
        background-image: url('../../../img/homeYMYT/addv/service04.png');
      }
      .addv-item-hover5 {
        background-image: url('../../../img/homeYMYT/addv/service05.png');
      }
      .addv-item-hover6 {
        background-image: url('../../../img/homeYMYT/addv/service06.png');
      }
      .addv-item-hover7 {
        background-image: url('../../../img/homeYMYT/addv/service07.png');
      }
      .addv-item-hover8 {
        background-image: url('../../../img/homeYMYT/addv/service08.png');
      }
      span {
        width: 100%;
        text-align: center;
        padding: 2px 10px;
        color: #333;
        font-size: 12px;
        word-break: break-all;
      }
    }
  }
}

.main-container {
  width: 1320px;
  margin: 40px auto;
  overflow: hidden;
  .right-container {
    padding: 20px 15px;
    margin: 8px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 2px rgba(198, 198, 198, 0.36);
    .search_text input {
      border: none;
      outline: none;
      width: 100%;
      height: 60px;
      background: rgba(241, 241, 241, 1);
      // url("../../../img/homeYMYT/search-icon.png") no-repeat 95% center;
      border-radius: 8px;
      padding: 0px 10px;
      margin-bottom: 20px;
      font-size: 16px;
      &:focus + .ant-input-suffix .ant-input-search-icon {
        color: #3d85ff !important;
      }
    }
    .ant-input-affix-wrapper .ant-input-suffix {
      top: 40%;
    }
    .ant-input-search-icon {
      font-size: 28px;
    }
    .portal div {
      height: 60px;
      background: rgba(61, 133, 255, 1);
      border-radius: 8px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 60px;
      text-align: center;
      margin-bottom: 15px;
    }
    .qrcode {
      p {
        text-align: center;
        height: 25px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 25px;
      }
      background-size: 100%;
      .app1,
      .app2,
      .app3,
      .app4 {
        width: 145px;
        height: 145px;
        background: no-repeat;
        background-size: 100%;
      }
      .app1 {
        background-image: url('../../../img/homeYMYT/qrcode/qrcode-app1.png');
      }
      .app2 {
        background-image: url('../../../img/homeYMYT/qrcode/qrcode-app2.png');
      }
      .app3 {
        background-image: url('../../../img/homeYMYT/qrcode/qrcode-app3.png');
      }
      .app4 {
        background-image: url('../../../img/homeYMYT/qrcode/qrcode-app4.png');
      }
    }
  }
}

.rhicp-container {
  width: 100%;
  height: 70vh;
  background-color: #f9f9f9;
  padding-top: 49px;
  p {
    text-align: center;
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(12, 12, 12, 1);
    line-height: 56px;
    margin: 40px 0 10px;
  }
  .rhicp-img {
    display: block;
    height: 62vh;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1320px) {
  .product-container {
    height: 106vh;
    width: 1000px;
  }
  .addv-container {
    height: 106vh;
    width: 800px;
  }
}
@media screen and (max-width: 767px) {
  .@{content5}-wrapper {
    height: 2000px;
    overflow: hidden;
    .@{content5} {
      ul {
        li {
          display: block;
          width: 100%;
          padding: 2%;
          span {
            height: 168px;
          }
          p {
            position: relative;
            bottom: 0;
          }
        }
      }
    }
  }
  .rhicp-container {
    height: 35vh;
    padding: 10px;
    .rhicp-img {
      height: auto;
      width: 90%;
    }
  }
  .main-container {
    width: 100%;
    margin: 40px auto 10px;
  }
  .addv-container {
    width: 100%;
    height: 90vh;
  }
}
