.login_form_title {
  padding-top: 0px;
  border-bottom: 3px solid #c9c9c9;
  text-align: center;
  margin: 0 15px;
}
.login_form_title span {
  position: relative;
  background: #fff;
  padding: 10px;
  top: 20px;
  font-size: 22px;
  color: #e60012;
}
.login_form_input {
  padding: 35px 15px;
  padding-top: 14px;
  padding-bottom: 0;
}
.erro_msg {
  padding-left: 71px;
  height: 30px;
  font-size: 12px;
  color: #e60012;
}
ul,
ol {
  list-style: outside none none;
}
.login_form_input ul li {
  padding-bottom: 13px;
  position: relative;
}
.login_form_input label {
  position: relative;
  width: 71px;
  height: 30px;
  display: inline-block;
  letter-spacing: 17px;
  font-size: 18px;
}
.login_form_input .login_input {
  width: 350px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #dddddd;
}
input.mywuda_sub_18 {
  height: 38px;
  font-size: 18px;
  padding: 0px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #fb7604;
  border: 1px solid #fb7604;
  color: #fff;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
}
