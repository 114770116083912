.s-input {
  height: 40px;
  border: 1px solid #ccc;
}
input.mywuda_sub_18 {
  height: 38px;
  font-size: 18px;
  padding: 0px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #fb7604;
  border: 1px solid #fb7604;
  color: #fff;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
}
